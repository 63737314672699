import { ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '@environments/environment';
import { Formatter } from '@helpers/formatter';
import { DestroyService } from '@services/destroy.service';
import { UniswapV3Service } from '@services/onchain/uniswap-v3.service';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { CHAIN_IDS } from '@shared/constants/chain-ids.constant';
import { formatUnits } from 'ethers';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-balance',
  standalone: true,
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex g-flex--align-center',
  },
})
export class BalanceComponent implements OnInit, OnChanges {
  @HostBinding('class.g-flex--reverse')
  @Input()
  isReverse = false;
  @Input() tokenType: string | null = null;
  @Input() token: string | null = null;
  @Input() size: 'default' | 'small' | 'medium' = 'default';
  @Input() balance: string | number;
  @Input() isShowBalanceUsd = false;
  @Input() isShowZeroBalanceUsd = false;
  @Input() isShowZeroBalance = false;
  @Input() balanceUnformatted: number | undefined = undefined;
  @Input() isUsdNextLine = false;
  @Input() tokenPriceUsd = 0;
  @Input() oldBalance: string | number;
  @Input() oldBalanceUnformatted: number | undefined = undefined;

  tokenName: string | null = null;
  balanceUsd: string;
  oldBalanceUsd: string;

  protected readonly formatUnits = formatUnits;

  constructor(
    private uniswapV3Service: UniswapV3Service,
    private changeDetectorRef: ChangeDetectorRef,
    private destroy$: DestroyService,
  ) {}

  ngOnInit() {
    this.tokenName = this.getTokenName();
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { token, balance, isShowBalanceUsd, balanceUnformatted, oldBalanceUnformatted, oldBalance } = changes;

    if (token && token.previousValue !== token.currentValue) {
      this.tokenName = this.getTokenName();
    }

    if (Number(environment['CHAIN_ID']) === CHAIN_IDS.SONIC) {
      this.tokenPriceUsd = 0.001;
    }

    if (this.isShowBalanceUsd && balance && balance.previousValue !== balance.currentValue) {
      this.uniswapV3Service
        .sacraPrice$(Number(environment['CHAIN_ID']))
        .pipe(takeUntil(this.destroy$))
        .subscribe(p => {
          this.tokenPriceUsd = this.tokenPriceUsd === 0 ? p : this.tokenPriceUsd;

          this.balanceUsd = (
            (balanceUnformatted?.currentValue ? balanceUnformatted?.currentValue : +balance.currentValue) *
            this.tokenPriceUsd
          ).toFixed(1);

          this.changeDetectorRef.detectChanges();
        });
    }

    if (this.isShowBalanceUsd && oldBalance && oldBalance.previousValue !== oldBalance.currentValue) {
      this.uniswapV3Service
        .sacraPrice$(Number(environment['CHAIN_ID']))
        .pipe(takeUntil(this.destroy$))
        .subscribe(p => {
          this.tokenPriceUsd = this.tokenPriceUsd === 0 ? p : this.tokenPriceUsd;

          this.oldBalanceUsd = (
            (oldBalanceUnformatted?.currentValue ? oldBalanceUnformatted?.currentValue : +oldBalance.currentValue) *
            this.tokenPriceUsd
          ).toFixed(1);

          this.changeDetectorRef.detectChanges();
        });
    }

    this.changeDetectorRef.detectChanges();
  }

  private getTokenName() {
    if (this.tokenType) {
      return this.tokenType;
    }

    if (!this.token) {
      throw new Error(`Unknown token ${this.token}`);
    }

    const core = GET_CORE_ADDRESSES(Number(environment['CHAIN_ID']));
    switch (this.token.toLowerCase()) {
      case core.gameToken.toLowerCase():
        return 'sacra';
      case core.magicToken.toLowerCase():
        return 'matic';
      case core.strengthToken.toLowerCase():
        return 'usdc';
      case core.dexterityToken.toLowerCase():
        return 'tetu';
      default:
        throw new Error(`Unknown token ${this.token}`);
    }
  }

  formatAmount(number: string) {
    return Formatter.formatCurrency(+number);
  }

  checkBalance() {
    if (this.isShowZeroBalance) {
      return this.balance !== undefined && this.balance !== null;
    }
    return this.balance;
  }
}
