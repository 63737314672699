import { Injectable } from '@angular/core';
import { ReinforcementController__factory } from '@data/abi';
import { TransactionDataModel } from '@models/transaction-data.model';
import { ErrorService } from '@services/error.service';
import { FeesExtension, ON_CHAIN_CALL_DELAY, ON_CHAIN_CALL_RETRY } from '@services/onchain/FeesExtension';
import { RelayService } from '@services/onchain/relay.service';
import { ProviderService } from '@services/provider.service';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { adjustGasLimit } from '@shared/utils';
import { NGXLogger } from 'ngx-logger';
import { catchError, concatMap, from, retry, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReinforcementService extends FeesExtension {
  constructor(
    private providerService: ProviderService,
    private errorService: ErrorService,
    private logger: NGXLogger,
    private relayService: RelayService,
  ) {
    super();
  }

  // --- FACTORIES ---

  createReinforcementController(chainId: number) {
    return ReinforcementController__factory.connect(
      GET_CORE_ADDRESSES(chainId).reinforcementController,
      this.providerService.getProviderForRead(),
    );
  }

  // --- VIEWS ---

  heroInfo$(chainId: number, heroAdr: string, heroId: number) {
    this.logger.trace('heroInfo', chainId, heroAdr, heroId);
    return from(this.createReinforcementController(chainId).heroInfo(heroAdr, heroId)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  heroInfoV2$(chainId: number, heroAdr: string, heroId: number) {
    this.logger.trace('heroInfoV2', chainId, heroAdr, heroId);
    return from(this.createReinforcementController(chainId).heroInfoV2(heroAdr, heroId)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  isStakedV2$(chainId: number, heroAdr: string, heroId: number) {
    this.logger.trace('isStakedV2', chainId, heroAdr, heroId);
    return from(this.createReinforcementController(chainId).isStakedV2(heroAdr, heroId)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  heroesByBiomeV2$(chainId: number, biome: number) {
    this.logger.trace('heroesByBiomeV2', chainId, biome);
    return from(this.createReinforcementController(chainId).heroesByBiomeV2(biome)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  getHitsNumberPerLast24Hours$(chainId: number, biome: number) {
    this.logger.trace('getHitsNumberPerLast24Hours', chainId, biome);
    return from(this.createReinforcementController(chainId).getHitsNumberPerLast24Hours(biome)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  getFeeAmount$(chainId: number, biome: number) {
    return this.getHitsNumberPerLast24Hours$(chainId, biome).pipe(
      switchMap(hitsLast24h => {
        this.logger.trace('getFeeAmount', chainId, hitsLast24h, biome);
        return from(this.createReinforcementController(chainId).getFeeAmount(hitsLast24h, biome));
      }),
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  earned$(chainId: number, hero: string, id: number) {
    this.logger.trace('earned', chainId, hero, id);
    return from(this.createReinforcementController(chainId).earned(hero, id)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  // --- CALLS ---

  stakeHeroV2$(account: string, chainId: number, heroAdr: string, heroId: number, fee: bigint) {
    this.logger.trace('stakeHeroV2', chainId, heroAdr, heroId, fee);
    return from(this.createReinforcementController(chainId).stakeHeroV2.estimateGas(heroAdr, heroId, fee)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Stake hero',
            isNeedUpdateHero: true,
            txPopulated: this.createReinforcementController(chainId).stakeHeroV2.populateTransaction(
              heroAdr,
              heroId,
              fee,
            ),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  withdrawHero$(account: string, chainId: number, heroAdr: string, heroId: number) {
    return from(this.createReinforcementController(chainId).withdrawHero.estimateGas(heroAdr, heroId)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'UnStake hero',
            isNeedUpdateHero: true,
            isNeedUpdateBalances: true,
            txPopulated: this.createReinforcementController(chainId).withdrawHero.populateTransaction(heroAdr, heroId),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  withdrawHeroV2$(account: string, chainId: number, heroAdr: string, heroId: number) {
    return from(this.createReinforcementController(chainId).withdrawHeroV2.estimateGas(heroAdr, heroId)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'UnStake hero v2',
            isNeedUpdateHero: true,
            isNeedUpdateBalances: true,
            txPopulated: this.createReinforcementController(chainId).withdrawHeroV2.populateTransaction(
              heroAdr,
              heroId,
            ),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  claimAll$(account: string, chainId: number, heroAdr: string, heroId: number) {
    return from(this.createReinforcementController(chainId).claimAll.estimateGas(heroAdr, heroId)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Claim staked rewards',
            isNeedUpdateHero: true,
            isNeedUpdateBalances: true,
            txPopulated: this.createReinforcementController(chainId).claimAll.populateTransaction(heroAdr, heroId),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  claimLastItem$(account: string, chainId: number, heroAdr: string, heroId: number, count = 30) {
    return from(this.createReinforcementController(chainId).claimNft.estimateGas(heroAdr, heroId, count)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Claim last NFTs',
            isNeedUpdateHero: true,
            isNeedUpdateBalances: true,
            txPopulated: this.createReinforcementController(chainId).claimNft.populateTransaction(
              heroAdr,
              heroId,
              count,
            ),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }
}
