@if (loading) {
  <app-loading-small class="page-loader"></app-loading-small>
} @else {
  <app-dialog-title
    [title]="'hero-level-up.title' | translate"
    [hasTitleScratch]="true"
    (closeDialog)="close()">
  </app-dialog-title>

  <div class="container">
    <div class="stat-container">
      @for (coreStat of HERO_CORE_STATS; track coreStat) {
        <div class="element g-flex g-flex--align-center g-flex--space-between">
          <div class="g-flex g-flex--align-center">
            <div class="element__container g-flex-column g-flex--align-center">
              <div class="g-flex g-flex--align-center">
                <img
                  class="element__img"
                  [src]="coreStat.icon"
                  alt="" />
              </div>

              <img
                class="element__top-border"
                src="/assets/images/ui/dividers/borders_v/border_v_up.png"
                alt="" />
            </div>

            <div class="app-divider-group-of-bolts"></div>

            <div class="element__title g-flex-column">
              <div class="element__title-name app-btn_font-size">
                {{ coreStat.name | translate }}
              </div>

              <div class="element__title-desc app-btn-small_font-size">
                {{ coreStat.description ?? '' | translate }}
              </div>
            </div>
          </div>

          <app-buttons-level-up
            class="buttons-level-up"
            [points]="stats"
            [value]="getHeroValueByKey(coreStat.id)"
            [valueLevel]="coreStatsChange[coreStat.id]"
            [valueName]="coreStat.id"
            (down)="changeStat($event, false)"
            (up)="changeStat($event, true)">
          </app-buttons-level-up>
        </div>
      }
    </div>

    <div class="actions g-flex g-flex--align-center g-flex--space-between">
      <div class="available">
        {{ 'hero-level-up.available' | translate }}: <span class="available__value">{{ stats }}</span>
      </div>

      @if (!!fee && fee.accountBalance !== ZERO_BI && fee.allowance < needForLevelUp()) {
        <button
          class="btn-level-up app-btn-blue-3"
          appButtonClick
          [isDisabled]="waiting"
          (hostClick)="approve()">
          {{ 'hero-level-up.buttons.assign' | translate }}
        </button>
      }

      @if (!(!!fee && fee.accountBalance !== ZERO_BI && fee.allowance < needForLevelUp())) {
        <button
          class="btn-level-up app-btn-red-3"
          appButtonClick
          [isNeedConfirm]="true"
          [infoDesc]="isButtonLevelUpDisabled().reason"
          [isDisabled]="isButtonLevelUpDisabled().status"
          (hostClick)="levelUp()">
          {{ 'hero-level-up.buttons.level-up' | translate }}
        </button>
      }

      <button
        (click)="reset()"
        class="btn-reset app-btn-blue-1-2">
        {{ 'hero-level-up.buttons.reset' | translate }}
      </button>
    </div>

    @if (!!fee && !isFreeLvlUp) {
      <div class="balance g-flex-column g-flex--align-center gap-24">
        <div class="info g-flex g-flex--align-center g-flex--justify-center gap-12">
          <div>
            {{ 'hero-level-up.cost' | translate }}
          </div>

          <app-balance
            [isReverse]="false"
            [balance]="needForLevelUpN()"
            [isShowBalanceUsd]="true"
            [tokenType]="'sacra'">
          </app-balance>
        </div>

        @if (!isEnoughBalance()) {
          <button
            class="btn-buy app-btn-silver-3 g-flex g-flex--align-center g-flex--justify-center"
            appButtonClick
            [isNeedConfirm]="true"
            [isDisabled]="waiting"
            (hostClick)="buyTokens()">
            {{ (waiting ? 'hero-level-up.buttons.buying' : 'hero-level-up.buttons.buy') | translate }}

            <img
              class="btn-buy__icon shadow__icon"
              [src]="'assets/images/ui/icons/tokens/sacra.png'"
              alt="" />
          </button>
        }
      </div>
    }
  </div>
}
